.rodape {
  background-color: var(--preto);
  display: flex;
  justify-content: center;
  padding: 1em 20em;
}

.rodape h2 {
  color: var(--branco);
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}