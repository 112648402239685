.container {
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}

.link {
  color: var(--preto);
  font-weight: 700;
  padding: 10px 20px;
  background: var(--cinza);
  border: 2px solid var(--preto);
  border-radius: 10px;
  text-decoration: none;
  width: 350px;
}

.link:hover {
  background: var(--hover);
}